import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs';
import {
  AskAssistant,
  ClearChat,
  SetChatWindowVisibility,
} from '../data/actions/co-pilot.actions';
import { AppState } from '../data/state/app.state.model';
import { ICoPilotMessage } from '../data/state/co-pilot.state.model';
import { AuthService } from './auth.service';
import { UserSubscriptionService } from './user-subscriptions.service';

@Injectable({
  providedIn: 'root',
})
export class CopilotService {
  messages = this.store$
    .select((state) => state.coPilot.conversation.data.messages)
    .pipe(
      map((messages) =>
        messages.map((m) => ({
          ...m,
          content: this.domSanitizer.sanitize(
            SecurityContext.HTML,
            m.content
          ) as string,
        }))
      )
    );

  loading = this.store$.select(
    (state) => state.coPilot.conversation.status === 'loading'
  );

  chatWindowCurrentVisibility = this.store$.select(
    (state) => state.coPilot.conversation.windowOpened
  );

  chatIntroText = this.userSubscriptionService.activeSubscription$.pipe(
    map((sub) => sub?.appConfiguration?.copilotConfig?.introText)
  );
  featureEnabled = this.authService.isOwner$.pipe(
    withLatestFrom(this.userSubscriptionService.activeSubscription$),
    map(
      ([hasOwnerRole, activeSubscription]) =>
        hasOwnerRole &&
        activeSubscription?.appConfiguration.copilotEnabled === true
    )
  );
  constructor(
    private store$: Store<AppState>,
    private userSubscriptionService: UserSubscriptionService,
    private domSanitizer: DomSanitizer,
    private authService: AuthService
  ) {}

  clearChat() {
    this.store$.dispatch(new ClearChat());
  }

  sendUserMessage(userMessage: string) {
    if (!userMessage) {
      return;
    }
    const message: ICoPilotMessage = { role: 'user', content: userMessage };
    this.store$.dispatch(new AskAssistant(message));
  }

  setChatWindowVisibility(opened: boolean) {
    this.store$.dispatch(new SetChatWindowVisibility(opened));
  }
}
